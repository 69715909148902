import React from "react";
import LayoutEventos from "../components/layouts/layoutEventos.js";
import Detalle from "../templates/adwords/detalle.js";
import Helmet from "react-helmet";


export default function AdWords(props) {
  const path = props.params["*"];
  if (path && path.match(/.+/)) {
    return (
      <LayoutEventos>
        <Helmet htmlAttributes={{ lang: "es" }} />
        <Detalle slug={path} location={props.location} props={props} />
        </LayoutEventos>
    );
  } else {
    return (
      <LayoutEventos>
        <Helmet htmlAttributes={{ lang: "es" }} />
      </LayoutEventos>
    );
  }
}
