import React, { useState, useEffect } from "react";
import Image from "../../images/icons/btn_download.png";
import iconDocs from "../../images/icons/btn_doc.png";
import { Tab, Tabs, Row, Col, Container, Button, TabContainer } from "react-bootstrap";

import CotizadorNuevo from "./cotizador-nuevo";

export default function TabsVersionesLujo ({car, versions, marca, modelo, year, setShowModalSale}){

    const landingUrl = "https://d3s2hob8w3xwk8.cloudfront.net/autos-landing";

/*     console.log(car, versions, marca, modelo, year, "Versionsss") */

    const [ showSeguros, setShowSeguros ] = useState(false);
    const [ showCotizador, setShowCotizador ] = useState(false);
    const [colPoscion, setColPoscion] = useState(4);
    const [specsUrl, setSpecsUrl] = useState(null);
    const [manualUrl, setManualUrl] = useState(null);
    const [catalogUrl, setCatalogUrl] = useState(null);

    const checkUrl = async (url, setter) => {
        fetch(url, {
          method: "HEAD",
        }).then((r) => {
          if (r.status === 200) {
            setter(url);
          } else {
          }
        });
      };
    
    useEffect(() => {
        if (versions) {
            if (modelo) {
            const pdfUrl = `${landingUrl}` + "/" + (marca ? marca.toLowerCase() : `chevrolet`) + "/" + (modelo ? modelo.replace(" ", "-").toLowerCase() + "-" + year.replace(".", "") : "") + "/pdf";

            if (versions) {
                if (modelo === "MARCH" || modelo === "V-DRIVE" || modelo === "VERSA" || modelo === "SENTRA" || modelo === "ALTIMA"  || modelo === "KICKS" || modelo === "KICKS E-POWER" 
                || modelo === "XTRAIL" || modelo === "XTRAIL E-POWER" || modelo === "PATHFINDER" || modelo === "NP300" || modelo === "FRONTIER"
                || modelo === "URVAN" || modelo === "FRONTIER-V6-PRO-4X" || modelo === "Z") {
                    setSpecsUrl(``);
                    setManualUrl(``);
                    setCatalogUrl(`${pdfUrl}/catalogo.pdf`);
                    setColPoscion(12);
                } else {
                    setSpecsUrl(`${pdfUrl}/ficha-tecnica.pdf`);
                    setManualUrl(`${pdfUrl}/manual.pdf`);
                    setCatalogUrl(`${pdfUrl}/catalogo.pdf`);
                    
                }
                /* setSpecsUrl(`${pdfUrl}/ficha-tecnica.pdf`);
                setManualUrl(`${pdfUrl}/manual.pdf`);
                setCatalogUrl(`${pdfUrl}/catalogo.pdf`); */
            }
            }
        }

    });

    const mappingVersionesTabs = versions.map((obj, i) => {
        const featuresMap = obj.features;

        let objVersion = {
            id: obj.id,
            modelo: car.model,
            anio: car.year,
            version: obj.name,
            precio: obj.price
        }

        return(
            
            <Tab.Container key={i} eventKey={obj.name} id={`ver-` + i} title={<div className="d-flex align-items-center justify-content-center tabsTitle" id={"ver-" + i}>{obj.name}</div>}>
                <Row className="bg-white">
                    <Col lg={6} md={6} sm={12} xs={12} className="px-0">
                        <Container className="p-4">
                            <div style={{color: 'rgb(101, 109, 120)'}}>
                                <h5>La versión <strong>{obj.name} {car.year}</strong> cuenta con:</h5>
                            </div>
                            <hr/>
                            <div>
                                {featuresMap?.map((subObj, subIndex) => (
                                    <li key={subIndex} className="filaFeatures-landingLujo">
                                        <img style={{width: 25, height: 25, marginRight: 17, marginLeft: 14}} src={subObj.icon}/>
                                        {subObj.name}
                                    </li>
                                ))}
                            </div>
                        </Container>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="px-0">
                        <Container className="p-4">
                            <div style={{color: 'rgb(101, 109, 120)'}} className="pb-2">
                                <h5>Plan de Financiamiento {obj.name} {car.year}</h5>
                            </div>
                            <div>
                                <CotizadorNuevo landing={true} infoCar={objVersion} marca={car.make}/>
                            </div>
                        </Container>
                    </Col>
                </Row>
                </Tab.Container>

        )
    })


    return(
    <>
        <Tabs >
            {mappingVersionesTabs}
        </Tabs>
    </>
    )
}