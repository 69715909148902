import React, { useState, useEffect } from "react";
import api from "../../actions/riveroapi";
import { Container, Row, Col } from "react-bootstrap";
import FormularioVentaNissan from "../../components/widgets/formularios/FormularioBasico";
import Image from "../../components/widgets/basico/image";
import TabsVersionesLujo from "../../components/widgets/TabsVersionesAdword";
import estrenaHoy from "../../images/Adwords/ESTRENA-HOY.png"

export default function AdWords({ slug, location, props }) {
  const [isLoading, setIsLoading] = useState(true);
  const [ad, setAd] = useState(null);
  const [model, setModel] = useState(null);
  const [year, setYear] = useState(null);
  const [make, setMake] = useState(null);
  const [promocion, setPromocion] = useState(null);
  const [como, setComo] = useState(null);
  const [evento, setEvento] = useState(slug);

  const [ car, setCar ] = useState();
  const [ selectColor, setSelectColor ] = useState({image: "", nombre: ""});
  const [ galeriaPrincipal, setGaleriaPrincipal ] = useState({imagen: "", index: 0});
  const [ pagoMensualInicio, setPagoMensualInicio ] = useState();
  const [ cargandoCar, setCargandoCar ] = useState(false);
  const [ versionCarro, setVersionCarro ] = useState();

  let propsSearc = props.location.search;
  const paramsSearch = new URLSearchParams(propsSearc);

  let source = paramsSearch.get("utm_source");
  let medium = paramsSearch.get("utm_medium");
  let campaing = paramsSearch.get("utm_campaign");
  let cnname = paramsSearch.get("cnname");
  let content = paramsSearch.get("utm_content");
  let term = paramsSearch.get("utm_term");

console.log(evento, "Slug");
  var origen = location.pathname.replaceAll("/", "-");

  var autoInteres = slug.replaceAll("-", " ");

  origen = origen.substring(1, origen.lenght);

  const nombre = location.search.split("=");

  useEffect(() => {
    var comoSeEntero = location.pathname.split("/")[1];

    if (comoSeEntero === "publicidad") {
      setComo("MailMarketing");
    } else if (comoSeEntero === "adwords") {
      setComo("Adwords");
    } else {
      setComo("gruporivero.com");
    }

    api.getCampaign(slug).then((r) => {

        setAd({...r.data,
          image: r.data.image.replace('/adwords/', '/campaigns/')
        });
        setIsLoading(false);
        var firstModel = r.data.select.split("-")[0];
        firstModel = firstModel.split(" ");
        setModel(firstModel[0]);
        setYear(firstModel[firstModel.length - 2]);
        setPromocion(r.data.select.split("-")[1]);
        setModel(car?.model);
        setYear(car?.year);
        setMake(car?.make);
        console.log(car?.model, "Nombreeeee");
      })
      .catch((error) => {
        console.log(`[Error, intente de nuevo ${error}`);
      });

      var limpiaSlug = slug;
      setCargandoCar(true)
      api.getCar(limpiaSlug ? limpiaSlug : limpiaSlug).then((response) => {
           console.log("response: ", response);
          setCar(response);
          setVersionCarro(response?.versions[0].name)
          setCargandoCar(false)

          let obj = {
              car_id: response.versions[0].id,
              entry_percentage: 25,
              months: 60,
              warraty_id: 1,
          }

          api.createQuotation(obj).then((r) => {
              setPagoMensualInicio(r.data.payment.monthly_payment);
          }).catch((error) => console.log("Hay un error en el calculador de mensualidad: ", error));
      });

  }, [slug, location.pathname]);

  if (isLoading) {
    return null;
  }

  function handleSubmit(e) {
    // Previene que el navegador recargue la página
    e.preventDefault();

    // Lee los datos del formulario
    const form = e.target;
    const formData = new FormData(form);
    console.log("Formulario",formData);

    // Puedes pasar formData como el cuerpo de la consulta directamente:
    /* fetch('/some-api', { method: form.method, body: formData }); */

    // O puedes trabajar con él como un objecto plano:
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);

    api.postAsistenciaEvento(formJson).then((r) => {
      alert('Registro Enviado');
      window.location.replace('');
      })
      .catch((error) => {
        alert('Error de conexión. Intente más tarde.');
        console.log(`Error, intente de nuevo ${error}`);
      });
  }

  function solicitudLead(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);
    if(formJson.nombre != "" || formJson.apellidos != "" || formJson.telefono != "" || formJson.correo != ""){
      e.target.submit();
    } else {
      alert('Complete todos los campos para continuar')
    }
  }


  return (
    //EVENTO NISSAN KICK START

/*     <Container>
      <div style={{backgroundColor: 'black'}}>
        <Row className="m-0">
          <Image fileName={`topEventoKicks.png`} alt="KICKSTART" imgStyles={{ width: "100%" }} style={{ width: "100%" }}/>
        </Row>
        <Row style={{display:'flex', justifyContent: 'center'}}>
          <div className="py-4" style={{color: 'white', width: '90%',}}>
            <h3 class="detona">DETONA TU INSTINTO EN NISSAN RIVERO.</h3>
            <hr/>
            <h5 className="texto-evento">Ven a vivir una experiencia única y sé testigo<br/>
            de la revelación del nuevo <span className="m-0" style={{fontFamily: 'Arial', fontSize: '1em', color: '#b6cdf1'}}>Nissan Kicks 2025. </span><br/>
            Prepárate para una noche llena de sorpresas con<br/>
            comida, bebida, música y mucho más.
            </h5>
          </div>
          </Row>
          <Row className="m-0 pb-2" style={{backgroundColor: '#b00e1b'}}>
            <Col className="pt-4" xs={12} md={7} lg={7}>
              <div>
                <Image fileName={`downEventoKicks.png`} alt="KICKSTART" imgStyles={{ width: "100%"}} style={{ width: "100%"}}/>
              </div>
            </Col>
            <Col style={{paddingTop: '3em'}} xs={12} md={5} lg={5}>
              <div>
                <h3 className="p-0 m-0" style={{fontFamily: 'Narrow', color: 'white'}}>Confirma tu</h3><br/>
                <h3 className="p-0" style={{fontFamily: 'Narrow', color: 'white', marginTop: '-30px'}}>asistencia:</h3>
              </div>
              <div style={{color: 'white', backgroundColor: 'black', padding: '1em'}}>
              <form onSubmit={handleSubmit} method="POST" >

              <input hidden id="evento" maxlength="255" name="evento" size="20" type="text" value={evento}/>

                <div className="form-group pb-2">
                  <label style={{width: '70px'}} className="p-2" for="nombre">Nombre:</label><input className="p-2" placeholder="Nombre" id="nombre" maxlength="40" name="nombre" size="30" type="text" required />
                </div>

                <div className="form-group pb-2">
                    <label style={{width: '70px'}} className="p-2" for="telefono">Teléfono:</label><input className="p-2" placeholder="Telefono" id="telefono" maxlength="10" name="telefono" size="30" type="text" required />
                </div>
                  
                <div className="form-group pb-2">
                    <label style={{width: '70px'}} className="p-2" for="email">Email:</label><input className="p-2" placeholder="Correo" id="email" maxlength="80" name="email" size="30" type="text" required />
                </div>

                <div className="form-group pb-2">
                    <label className="p-2" htmlFor="name">Agencia: </label>
                    <select id="agencia" className="p-2" name="agencia" title="Agencia">
                      <option value="Contry">Contry</option>
                      <option value="Valle Oriente">Valle Oriente</option>
                      <option value="Valle">Valle</option>
                    </select>
                </div>

                <div className="form-group p-2 d-flex justify-content-center">
                    <input type="submit" className="btn bg-white" value="ENVIAR ASISTENCIA" />
                </div>
                </form>
              </div>
            </Col>
          </Row>
      </div>
    </Container> */
<>
  <Container style={{maxWidth: '2000px'}}>
    <div className="p-0 m-0" style={{backgroundImage: 'url('+ ad?.image + ')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
      <Row>
        <Col className="p-0 containerFondoVacio" xl={7} lg={7} md={6} sm={12}>

        </Col>
        <Col className="pl-4" xl={5} lg={5} md={6} sm={12}>
          <div className="containerFormularioAdword">
            <div style={{textAlign: 'center'}}>
              <img className="adwordEstrenaHoy" src={estrenaHoy} alt="Estrena hoy" />
            </div>
            
            <form onSubmit={solicitudLead} action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DHp000005bwkK" method="POST" >
              {/* <form onSubmit={solicitudLead} method="POST" > */}

              <div className="form-group" >
                <input hidden name="oid" value="00DHp000005bwkK"/>
                <input hidden id="00NHp0000194HWY" name="00NHp0000194HWY" value="Nuevos"/>
                <input hidden name="retURL" value="https://nissanrivero.com/gracias-por-contactarnos/nissan"/>
                <input hidden id="00NHp0000194HVl" maxlength="255" name="00NHp0000194HVl" size="20" type="text" value={autoInteres.toUpperCase()} />
                <input hidden id="00NVn000002CXS5" maxlength="255" name="00NVn000002CXS5" size="20" type="text" value={content ? content : ""}/>
                <input hidden id="00NVn000002CXVJ" maxlength="255" name="00NVn000002CXVJ" size="20" type="text" value={medium ? medium : ""}/>
                <input hidden id="00NVn000002CXYX" maxlength="255" name="00NVn000002CXYX" size="20" type="text" value={source ? source : ""}/>
                <input hidden id="00NVn000002CXbl" maxlength="255" name="00NVn000002CXbl" size="20" type="text" value={term ? term : ""}/>
                <input hidden id="Campaign_ID" name="Campaign_ID" value={campaing ? campaing : "701Vn00000HA7g0IAD"} /> {/** cid */}
                <input hidden id="GRI_Codigo_Campana__c" name="GRI_Codigo_Campana__c" value={campaing ? campaing : "701Vn00000HA7g0IAD"} />
                <input hidden id="00NHp0000194HWd" maxlength="255" name="00NHp0000194HWd" size="20" type="text" value={cnname ? cnname : ""}/>
                <input hidden id="00N2S000007ThUK" name="00N2S000007ThUK" value={`https://nissanrivero.com` + props.location.pathname} />
                <input hidden id="lead_source" name="lead_source" value="Internet" /> {/** Origen del prospecto */}
                <input hidden id="url" name="url" value={`https://nissanrivero.com` + props.location.pathname} />{/** pagina Origen */}
                <input hidden id="00NHp0000194HUw" name="00NHp0000194HUw"  value={source ? source : "nissanrivero.com"}/>
              </div>

              {/* <input hidden id="evento" maxlength="255" name="evento" size="20" type="text" value={evento}/> */}

              <div className="form-group pb-2">
                <label className="labelAdword" for="nombre">Nombre *</label><br/>
                <input className="inputAdword p-2 mb-1" placeholder="Nombre" id="first_name" maxlength="40" name="first_name" size="30" type="text" required />
              </div>

              <div className="form-group pb-2">
                <label className="labelAdword" for="nombre">Apellidos *</label><br/>
                <input className="inputAdword p-2 mb-1" placeholder="Apellidos" id="last_name" maxlength="40" name="last_name" size="30" type="text" required />
              </div>

              <div className="form-group pb-2">
                  <label className="labelAdword" for="telefono">Teléfono *</label><br/>
                  <input className="inputAdword p-2 mb-1" placeholder="Telefono" id="mobile" maxlength="10" name="mobile" size="30" type="text" required />
              </div>
                
              <div className="form-group pb-2">
                  <label className="labelAdword" for="email">Correo *</label><br/>
                  <input className="inputAdword p-2 mb-1" placeholder="Correo" id="email" maxlength="80" name="email" size="30" type="text" required />
              </div>

              {/* <div className="form-group pb-2">
                  <label className="p-2" htmlFor="name">Agencia: </label>
                  <select id="agencia" className="p-2" name="agencia" title="Agencia">
                    <option value="Contry">Contry</option>
                    <option value="Valle Oriente">Valle Oriente</option>
                    <option value="Valle">Valle</option>
                  </select>
              </div> */}

              <div className="form-group p-2 d-flex justify-content-center">
                <input type="submit" className="botonAdword text-white px-4 py-0" value="ENVIAR" onChange={solicitudLead} />
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </div>
    <div className="containerVersionesAdword" style={{maxWidth: '2000px'}}>
      <Row className="backgroundOscuro p-2 m-0">
        <div className="mt-4 pb-4 text-white">
            <center><h3>Versiones</h3></center>
            <center><Separador/></center>
        </div>
        <TabsVersionesLujo 
            car={car ? car : []}
            versions={car ? car.versions : []}
            marca={car ? car.make : null}
            modelo={car ? car.model : null}
            year={car ? car.year : null}
        />
      </Row>
    </div>
  </Container>
  </>
  );
  

/*     <Container className="pt-3">
      <h1 className="text-white">{ad ? ad.title : ""}</h1>
      <Row>
        <Col xs={12} md={6} lg={8}>
          {ad ? (
            <img src={ad.image}
              style={{ width: "100%" }} alt={ad.title} />
          ) : null}
        </Col>
        <Col xs={12} md={6} lg={4}> */
          {/* <FormularioVentaNissan
            pagina={`${origen ? origen + como : ""}`}
            titulo="Solicitar información"
            fullauto={"Chevrolet " + model + " " + year}
          >
            {ad.select ? (
              <CarSelect
                values={ad.select}
                setModelo={setModel}
                Anio={setYear}
                Promocion={setPromocion}
              />
            ) : null}
            <input
              type="hidden"
              id="Campaign_ID"
              name="Campaign_ID"
              value={nombre ? nombre[1] : ""}
              hidden
            />
            <input
              type="hidden"
              id="00Nf400000UBhZ6"
              name="00Nf400000UBhZ6"
              value={como}
            />
            <input
              type="hidden"
              id="00Nf400000UBhZt"
              name="00Nf400000UBhZt"
              value="Nuevos"
              hidden
            />

            <input
              type="hidden"
              id="00Nf400000UBhZ5"
              name="00Nf400000UBhZ5"
              value={origen ? origen + " " + promocion : ""}
            />
          </FormularioVentaNissan> */}
{/*         </Col>
      </Row>
    </Container> */}


function CarSelect(props) {
  const { values, setModelo, Anio, Promocion } = props;

  const selectList = values.split(";");
  const cantidad = selectList.length;
  const list = selectList.map((product) => (
    <option key={product}>{product}</option>
  ));
  function handleChange(product) {
    var valor = product.target.value;
    var modelos = valor.split(" - ")[0];
    var model = modelos.split(" ");
    console.log(modelos);
    setModelo(model[0]);
    Anio(model[model.length - 1]);
    Promocion(valor);
  }
  return (
    <div className="form-group" hidden={cantidad === 1}>
      <label htmlFor="adwords-input">Selecciona un carro</label>
      <select className="form-control" id="adwords-input" onChange={handleChange}>
        {list}
      </select>
    </div>
  );
}}

//Separador se usa en tres paginas
export function Separador() {
    return (
        <center className="p2 mt-4" >
            <div className="hr-separador-1 pb-3"></div>
            <div className="hr-separador-2"></div>
        </center>
    )
}
